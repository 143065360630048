/* General navbar classes  */
body{
    margin:0;
    padding: 0;
}
.nav-container{
    /* position: fixed;
    background-color: #333; */
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: auto;
    align-items: flex-start;
    opacity:500
    
}



.nav-hamburguer-icon{
    font-size: 1.2em;
    display: block;
    align-items: flex-start;
    padding-left: 2%;
}
.nav-hamburguer-icon:hover{
    color:grey;
}

.nav{
    display: flex;
    /* justify-content: space-between; */
    align-items: left;
    padding: 1px;
    margin-top: 1%;
    margin-bottom: 1%;

   
}





.nav-brand{
    
   
    font-size: 2.7vw;
    font-weight: 400;
    margin-bottom: 11px;
    margin-top: 1px;
    margin-right: 40px;
}


.nav_nodes{
    font-size: 1.5vw;
        font-weight: 400;
        margin-left: auto; 
        margin-right: 10;
}

.nav-mobile-brand{
    font-size: 6vw;
        font-weight: 400;
        margin-left: 100; 
        margin-right: auto;
        text-align: left;
        padding: 4px;
}

.nav-mobile-hamburger
{
    margin-left: 0px;
}

.nav-links{
    display:none;
    align-items: center;
    font-size: 1.2vw;
    font-weight: 100;
    margin-right: 0px;
}

@media screen and (min-width: 600px) {
    .nav-links{
        font-size: 1.0vw;
        font-weight: 50;
        margin-right: 0px;
       vertical-align: bottom;
       
        /* align-items: center; */
        display:flex;
        flex-direction: row;
    }

    .nav-mobile-brand{
        font-size: 1.5vw;
            font-weight: 400;
            margin-left: 10; 
            margin-right: auto;
    }

    .nav_nodes{
        font-size: 1.5vw;
        font-weight: 100;
        margin-left: auto; 
        margin-right: 10;
       /* margin-right: 200px; */
    }

    .nav-links > a{
        color:white;
        text-decoration: none;
        font-size: 1.2em;
    }
    .nav-links > a:not(:last-child){
        margin-right: 1em;
    }
    .nav-hamburguer-icon{
        display:none;
    }
    .overlay-container-hidden{
        display: none;
    }
}

/*Overlay menu classes */
.overlay-container-show{
    overflow: hidden;
    position: fixed;
    background-color: black;
    opacity: 50;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    transition: left linear 500ms;
}

.overlay-container-hidden{
    overflow: hidden;
    position: fixed;
    background-color: black;
    opacity: 0.1;
    top: 0;
    right: 0;
    bottom: 0;
    left: -600px;
    width: 100%;
    z-index: 1;
    transition: left linear 500ms;
}

.overlay-buttonClose{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: .5em;
    font-size: 4em;
}

.overlay-menu-wrapper{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.overlay-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 25em;
    opacity:100;
}

.overlay-menu a{
    font-size: 1em;
    font-weight: 100;
    text-decoration: none;
    color: #818181;
    transition: 0.3s;
}

.overlay-menu a:hover, .overlay-menu a:focus {
    color: #f1f1f1;
  }

.overlay-menu-item:not(:last-child){
    margin-bottom: .3em;
}


