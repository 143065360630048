
.app{
    width: 100vw; 

    background-image: linear-gradient(to right,rgb(240, 240, 240), rgb(237, 233, 234));
}


.HomeTopCarousel{
    margin-top: 2%;
}

