.nav {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  flex-direction: row;
}


.container_scroll {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}

.container_scroll > h2 {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 500;
  font-size: medium;
  opacity: 0.6;
  margin-top: 7px;
  transition: transform 50ms ease-out;
  border-radius: 1px;
  border: 0.1px solid grey;
  background: whitesmoke;
  padding: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 0.4rem;
  cursor: pointer;
  box-sizing: content-box;
  width: max-content;
  text-align: center;
  vertical-align: middle;
}




@media only screen and (max-width: 850px) {
  .container_scroll > h2 {
    cursor: pointer;
    margin-left: 18px;
    margin-right: 5px;
    font-weight: 400;
    opacity: 0.6;
    margin-top: 12px;
    transition: transform 50ms ease-out;
    /* border-radius:1px;border:1px ;background-image:linear-gradient(288.83deg, rgba(60, 72, 109, 0.4) 0%, rgba(165, 72, 221, 0.8) 100%);-webkit-background-size:cover;background-size:cover;cursor:pointer; */
    border-radius: 12px;
    border: 0.1px solid grey;
    background: smokewhite;
    padding: 5px;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    border-radius: 0.3rem;
    cursor: pointer;
    box-sizing: content-box;
    width: 80px;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    height: 4px;
  }

  .container_scroll > h10 {
    margin-left: 50px;
    margin-right: 18px;
    margin-top: 30px;
    font-weight: 500;
    opacity: 0.8;
    font-size: 18px;
    /* box-sizing: content-box; */
    text-align: center;
    color: blue;
    text-decoration-line: underline;
  }
}

.nav > h2 {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 400;
  font-size: large;
  opacity: 0.6;
  margin-top: 7px;
  transition: transform 50ms ease-out;
  border-radius: 1px;
  border: 0.1px solid grey;
  background: whitesmoke;
  padding: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4rem;
  cursor: pointer;
  box-sizing: content-box;
  width: max-content;
  text-align: center;
  vertical-align: middle;
}

@media only screen and (max-width: 850px) {
  .nav > h2 {
    cursor: pointer;
    margin-left: 18px;
    margin-right: 5px;
    font-weight: 400;
    opacity: 0.6;
    margin-top: 12px;
    transition: transform 50ms ease-out;
    /* border-radius:1px;border:1px ;background-image:linear-gradient(288.83deg, rgba(60, 72, 109, 0.4) 0%, rgba(165, 72, 221, 0.8) 100%);-webkit-background-size:cover;background-size:cover;cursor:pointer; */
    border-radius: 12px;
    border: 0.1px solid grey;
    background: smokewhite;
    padding: 5px;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    border-radius: 0.3rem;
    cursor: pointer;
    box-sizing: content-box;
    width: 80px;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    height: 4px;
  }

  .nav > h10 {
    margin-left: 50px;
    margin-right: 18px;
    margin-top: 30px;
    font-weight: 500;
    opacity: 0.8;
    font-size: 18px;
    /* box-sizing: content-box; */
    text-align: center;
    color: blue;
    text-decoration-line: underline;
  }
}

.nav > h3 {
  margin-left: 12px;
  margin-right: 18px;
  margin-top: -18px;
  margin-bottom: -10px;
  /* font-weight: 1500;  */
  opacity: 1;
  font-size: 40px;
  box-sizing: content-box;
  width: 160px;
  text-align: center;
  padding-top: 2px;
}

.nav > h4 {
  margin-left: 6px;
  margin-right: 18px;
  margin-top: -15px;
  margin-bottom: -15px;
  font-weight: 500;
  opacity: 0.8;
  font-size: 18px;
  /* box-sizing: content-box; */
  text-align: center;
  color: grey;
  padding-top: 2px;
}

.nav > h10 {
  margin-left: 50px;
  margin-right: 18px;
  margin-top: 30px;
  font-weight: 500;
  opacity: 0.8;
  font-size: 18px;
  /* box-sizing: content-box; */
  text-align: center;
  color: blue;
  text-decoration-line: underline;
}

.nav > h2:hover {
  opacity: 0.7;
  transform: scale(1.1);
  background-color: #8d624f;
  color: white;

  border: 0.1rem;
}

.nav > hr2 {
  display: block;
  height: 2px;
  width: 100%;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1rem 0;
  padding: 10;
}

/* .MDBNavbarItem{
  cursor:pointer;
} */

