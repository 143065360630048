.card-text2 {
  color: grey;
  margin-top: -14px;
  cursor: pointer;
  font-size: 1.8vw;
}

.card-text3 {
  color: black;
  font-size:x-large;
  font-weight: bolder;
  padding-top: 1%;
  padding-left: 2%;

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: x-large;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    font-size: x-large;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: large;
  }
}

.card-detail-description {
  margin-top: 2%;
  margin-right: 2%;

  color: black;
  font-size:large;
  padding: 2%;
  

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    margin-top: 0%;
    margin-bottom: -2%;
    margin-left: 0%;
    font-size:large;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    margin-bottom: -4%;
    margin-left: 2%;
    font-size:medium;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    margin-bottom: -8%;
    margin-left: 1%;
    font-size:small;
  }
}

.card-detail-series {
  margin-top: 2%;
  color: black;
  font-size:large;
  margin-bottom: -3%;
  padding: 2%;
  

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    margin-top: 1%;
    margin-bottom: -2%;
    font-size:large;
    margin-left: 0%;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    font-size:medium;
    margin-left: 2%;
    margin-bottom: -4%;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    margin-left: 1%;
    font-size:small;
  }
}

.card_show_all {
  padding-bottom: 40px;
}

.card-text_title {
  margin-top: -12px;
  color: black;
  text-align: left;
  font-size: 1.7vw;
}

.card {
  border-radius: 4px;
  background: smokewhite;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 1px 1px 36px;
  cursor: default;
  text-align: left;


}

.card:hover {
  transform: scale(1.0);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.img {
  width: 100%;
  display: block;
  height: 180px;
  object-fit: fill;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.h41 {
  display: inline-block;
  
}


.card-show-all-description {
  color: black;
  font-size: 1.6vw;

  @media screen and (min-width: 400px) and (max-width: 1200px) {
    font-size: 2.4vw;
  }

  @media screen and (min-width: 200px) and (max-width: 400px) {
    font-size: 2.8vw;
  }
}

.aboutus{
margin-left: 20%;
margin-top: 2%;
}


.about_us_body{
  margin-left: -2%;
  margin-right: 1%;

}
.about_us_text{
  margin-right: 1%;
  margin-left: 1%;
  font-size: small;

}

.Ady-pic{
  display: flex;
  // justify-content: center !important;
  // align-items: center !important;
  height: 100% !important;
  width: 100% !important;
  // margin-left: 35%;
  // margin-right: 40%;
}

.TermsOfUse_body{
margin-top: 1%;
  margin-left: 5%;
  margin-right: 5%;

}

.TermsOfUse_Card{
  margin-left: 15%;
  margin-top: 3%;
}


.what-is-image {
  transform:scale(1, 1);
  transition: 0.3s;
  padding: 1%;
  width: 90%;
  height: 68%;
  border-radius: 3%;
  border-color: black;

  &:hover {
      transform:scale(1.05, 1.05);
  }
} 





.Mind-Map {
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 0.5%;
  margin-left: 2%;
  margin-right: 2%;
  border: 2px solid #B2A6A6;
  border-radius: 1px;
  }

  .Mind-Map-Home {
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 0.5%;
    margin-left: 2%;
    margin-right: 2%;
    border: 1px solid #B2A6A6;
    border-radius: 1px;
    background-color: transparent !important;
    }



.LazyPeopleSearch4 {
  color: grey;
  padding-left: 2%;
  padding-top: 0.5%;
}

.LazyPeopleSearch3 {
  color: grey;
}

.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
  background-color: black;
}
.custom-carousel .carousel-indicators li {
  background-color: black;
  margin-bottom: -10%;
}
.MastheadButton1 {
 
  align-items: center;
 
 

}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.masthead-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  background-color: rgba(109, 109, 110, 0.7);
  color: #f2f2f2;
  padding: 10px 16px;
  margin-left: 10px;
  border-radius: 6px;
  border: 0;
  margin-top: 1em;
  font-family: main-font;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-out;

 

 

  &:hover {
      background-color: rgba(#6d6d6e, 1);
  }

  &:first-child {
      margin-left: 0;
      background-color: red;
      color: white;

      &:hover {
          background-color: darken(red, 5);
      }
  }

  span {
      margin-left: 8px;
  }
}
.slick-prev:before,
.slick-next:before {
  color: yellow;
}


.my-masonry-grid_column {
  padding-left: 0px; /* gutter size */
  
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: smokewhite;
  margin-bottom: 100px;
  margin-right: 40px;

  @media screen and (min-width: 600px) and (max-width: 900px) {
      margin-bottom: 100px;
      margin-right: 30px;
  }

  @media screen and (min-width: 100px) and (max-width: 600px) {
      margin-bottom: 100px;
  margin-right: 10px;
  }
}


