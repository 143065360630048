.Modal {
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw;
      height: 100vh;
      background: black;
      opacity: 1;
      pointer-events: all;
      z-index: 98;
  
      &.Modal__invisible {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
      }
    }
  
    &__wrp {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 90vw;
      height: calc(100vh - 100px);
      overflow-y: auto;
      max-width: 100%;
      max-height: 100%;
      z-index: 99;
      pointer-events: all;
     
      border-radius: 5px;
  

  
      &.Modal__invisible {
        z-index: -1;
        pointer-events: none;
      }
  
      &::-webkit-scrollbar {
        display: none;
      }
    }
  
    &__closebtn {
      position: absolute;
      top: 2%;
      right: 2%;
      z-index: 1;
      display: inline-flex;
      padding: 8px;
      font-size: 18px;
      border-radius: 50%;
      cursor: pointer;
      margin: 0 5px;
      margin-bottom: 0.8em;
      background: black;
      color: white;
      border: 1px solid white;
      transition: all 0.3s ease-out;
      outline: none;
  
      &:hover {
        background: white;
        color: black;
      }
    }
  
    &__image {
      &--wrp {
        position: relative;
      }
      &--img {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      &--shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20%;
        background: linear-gradient(180deg, transparent, rgba(14, 14, 14, 0.6), light-black);
      }
  
      &--buttonswrp {
        position: absolute;
        bottom: 10%;
        left: 1.5em;
        display: flex;
        align-items: center;
  
      }
  
      &--button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 120px;
        background-color: red;
        color: white;
        padding: 10px 16px;
        margin-left: 0;
        border-radius: 6px;
        border: 0;
        font-family: main-font;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s ease-out;
  
      
  
        &:hover {
          background-color: darken(red, 5);
        }
  
        span {
          margin-left: 8px;
        }
  
        &-circular {
          display: inline-flex;
          padding: 10px;
          border-radius: 50%;
          font-size: 13px;
          cursor: pointer;
          margin: 0 5px;
          margin-left: 0.8em;
          background: transparent;
          color: white;
          border: 1px solid white;
          transition: all 0.3s ease-out;
          outline: none;
  
       
  
          &:hover {
            background: white;
            color: black;
          }
  
          &:first-child {
            margin-left: 0;
            background: white;
            color: black;
  
            &:hover {
              background: rgba(white, 0.7);
              border-color: rgba(white, 0.7);
            }
          }
        }
      }
    }
  
    &__info {
      &--wrp {
        padding: 1em 1.5em;
  
   
      }
  
      &--title {
        font-size: 22px;
        line-height: 1.4;
        font-weight: 600;
        margin-bottom: 15px;
  
      }
  
      &--description {
        font-size: 14px;
        line-height: 1.7;
  
 
      }
  
      &--line {
        border-color: rgba(121,121,121,0.7);
        margin: 1em 0;
  
   
      }
  
      &--otherTitle {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 400;
        margin-bottom: 15px;
  
      }
  
      &--row {
        font-size: 12px;
        line-height: 1.6;
        margin: .5em;
        margin-left: 0;
        word-break: break-word;
  
        &-label {
          color: #777;
        }
  
        &-description {
          color: rgb(155, 60, 60);
          font-weight: 700;
        }
  
   
      }
    }
  }
.a1{
  text-align: center;
}
  .what-is-image {
   
    transition: 0.3s;
    padding: 1%;
    width: 100%;
  
    border-radius: 3%;
    border-color: black;
  
    &:hover {
        transform:scale(1.05, 1.05);
    }
  } 

  .card {
    width: auto;
   
    margin-left: -15%;
    // margin-right: -18%;
    margin-top: 0%;
    margin-bottom: 0%;
    border-radius: 4px;
    background: smokewhite;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    // padding: 14px 1px 1px 36px;
    cursor: default;
    text-align: left;
  
  
  }

  .card-group {

    display: flex;
  flex-direction: column;
  }


.book_genie_1 {
margin: 2%;
color: black;
}

.book_genie_2 {
  
  display: flex;
justify-content: center; /* Align horizontal */
}

.text_box {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
  margin-bottom: 4%;
}

.book_genie_title{
color:black;
  text-align: left;
  margin-left: -10%;
  margin-right: -10%;
}

.BookGenieResultsTitle1 {
margin-left: 4%;
margin-top: 2%;
margin-bottom: 1%;
font-size:large;
font-weight: 500;

}

.BookGenieResultsTitle2 {
  margin-left: 1%;
  margin-top: 2%;
  margin-bottom: 1%;
  font-size:large;
  font-weight: 700;
  color: rgb(155, 60, 60);
   
  }

  .BookGenieResultsBanner {
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 0%;
    font-size:large;
    font-weight: 500;
    color: rgb(155, 60, 60);
     
    }

 .LazyPeopleSearch1{
  margin-left: 5%;
  margin-top: -4%; 
  color: grey;
  
 }

 .LazyPeopleSearch33 {
border-radius: 0;
background-color: #fff;
border-color: #fff;
border-bottom: #fff;
border-left: #fff;
border-right: #fff;
text-decoration:underline ;
color: grey;
font-size: medium;
}

.d-flex {
  display: flex;
  flex-direction: row;
  text-align: center;
}



/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .d-flex {
    flex-direction: column;
    text-align: center;
  }
}

h1 {
  font-size: 48px;
}

@media (max-width: 480px) {
  h1 {
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  h1 {
    font-size: 24px;
  }
}


