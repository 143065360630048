.nav {
  display:flex;
  color: black;
  overflow:auto;
  grid-row: auto;
  // padding: 10px;
  // padding-left: 40px;
}


.nav > h2 {
  cursor: pointer;
  margin-left: 15px;
  margin-right: 5px;
  font-weight: 400;
  font-size: large;
  opacity: 0.6;
  margin-top: 7px;
  transition: transform 50ms ease-out;
  border-radius: 1px;
  border: 0.1px solid grey;
  background: whitesmoke;
  padding: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4rem;
  cursor: pointer;
  box-sizing: content-box;
  width: max-content;
  text-align: center;
  vertical-align: middle;
}

.nav > h2:hover {
  opacity: 0.7;
  transform: scale(1.1);
  background-color: #8d624f;
  color: white;

  border: 0.1rem;
}

.nav > h2_detail_page {
  cursor: pointer;
  margin-left: 2px;
  margin-right: 5px;
  font-weight:normal;
  font-size: smaller;
  opacity: 1.2;
  margin-top: 7px;
  transition: transform 50ms ease-out;
  border-radius: 0.5px;
  border: 1px whitesmoke;
  background: whitesmoke;
  padding: 4px;
  border-radius: 0.2rem;
  cursor: pointer;
  box-sizing: content-box;
  width: max-content;
  text-align: center;
  vertical-align: middle;
  padding-left: 2%;


  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: xx-small;
  }
}

.nav > h2_detail_page:hover {
  opacity: 1.7;
  transform: scale(1);
  background-color: whitesmoke;
  color: black;

  border: 0.1rem;
}



.nav > h21 {
  padding-left: 10px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 400;
  font-size: large;
  opacity: 0.6;
  margin-top: 1px;
  transition: transform 50ms ease-out;
  /* border-radius:1px;border:1px ;background-image:linear-gradient(288.83deg, rgba(60, 72, 109, 0.4) 0%, rgba(165, 72, 221, 0.8) 100%);-webkit-background-size:cover;background-size:cover;cursor:pointer; */
  border-radius: 1px;
  border: 0.1px solid grey;
  background: whitesmoke;
  padding: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0.4rem;

  cursor: pointer;
  box-sizing: content-box;
  width: max-content;
  text-align: center;
  vertical-align: middle;
}


.nav > h3 {
  margin-left: 12px;
  margin-right: 18px;
  margin-top: -18px;
  margin-bottom: -10px;
  opacity: 1;
  font-size: 40px;
  box-sizing: content-box;
  width: 160px;
  text-align: center;
  padding-top: 2px;
}

.nav > h4 {
  margin-left: 6px;
  margin-right: 18px;
  margin-top: -15px;
  margin-bottom: -15px;
  font-weight: 500;
  opacity: 0.8;
  font-size: 18px;
  text-align: center;
  color: grey;
  padding-top: 2px;
}

.nav > h10 {
  margin-left: 50px;
  margin-right: 18px;
  margin-top: 30px;
  font-weight: 500;
  opacity: 0.8;
  font-size: 18px;
  text-align: center;
  color: blue;
  text-decoration-line: underline;
}

.nav > carousel_header {
  margin-top:2%;
  // margin-bottom: 6px;
  font-weight:bold;
  opacity: 0.9;
  font-size:x-large;
  text-align: left;
  margin-left: 1.5%;
  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size:medium;
  }

}

.nav > name_detail_page {
  font-size: 2.6vw;
  margin-left: 0px;
  margin-right: 18px;
  margin-top: -12px;
  margin-bottom: 2px;
  font-weight: 500;
  opacity: 0.9;
  text-align: left;
}

.nav > name_show_all {
  font-size: 2vw;
  margin-left: 28px;
  margin-right: 8px;
  margin-top: -12px;
  margin-bottom: 2px;
  font-weight: 300;
  opacity: 0.9;
  text-align: left;
}

.nav > hr2 {
  display: block;
  height: 2px;
  width: 100%;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1rem 0;
  padding: 10;
}

.nav_all {
  display: flex;
  color: black;
  /* border-radius:8px;border:1px solid #48484A;background-image:linear-gradient(288.83deg, rgba(60, 72, 109, 0.2) 0%, rgba(165, 72, 221, 0.2) 100%);-webkit-background-size:cover;background-size:cover;cursor:pointer; */
}

.nav_all > h2_show_all {
  cursor: pointer;
  margin-left: 0px;
  margin-right: 5px;
  font-weight: 500;
  font-size: 1vw;
  opacity: 1.2;

  transition: transform 50ms ease-out;
  border-radius: 1px;
  border: 0.1px solid grey;
  background: whitesmoke;
  padding: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 0.2rem;
  cursor: pointer;
  box-sizing: content-box;
  width: max-content;
  text-align: left;

}

.nav_all > h2_show_all:hover {
  opacity: 1.7;
  transform: scale(1);
  background-color: #8caba8;
  color: black;

  border: 0.1rem;
}

.nav_all2 {
  // display: flex;
  color: black;
  align-items: center;

}

.nav_all2 > h2_show_all_celebrity {
  cursor: pointer;
  margin-left: 0px;
  margin-right: 5px;
  font-weight:light;
  font-size: smaller;
  opacity: 1.2;

  transition: transform 50ms ease-out;
  border-radius: 1px;
  border: 0px solid grey;
  background: whitesmoke;
  padding: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 0.2rem;
  cursor: pointer;
  box-sizing: content-box;
  width: max-content;
  text-align: center;

}

.nav_all2 > h2_show_all_celebrity:hover {
  opacity: 1.7;
  transform: scale(1);
  background-color: #8caba8;
  color: black;

  border: 0.1rem;
}

.name_font{
  margin-left:10px;
  font-size:small;
  color: rgb(95, 94, 94);

  
  // text-decoration:underline;
}



.detail_page_info{

  font-size: medium;
  color: black;
  text-decoration: none;
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: large;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    font-size: medium;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: small;
  }

}

.detail_page_info_year{

  font-size: medium;
  color: black;
  text-decoration: none;
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: large;
    margin-top: -2%;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    margin-top: -4%;
    font-size: medium;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    margin-top: -6%;
    font-size: small;
  }

}

.detail_page_info_rating{

  font-size: medium;
  color: black;
  text-decoration: none;
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: large;
    margin-top: -2%;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    margin-top: -4%;
    font-size: medium;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    margin-top: -6%;
    font-size: x-small;
  }

}

.detail_page_year_published{
 margin-top: -2.5%;
  font-size: medium;
  color: black;
  text-decoration: none;

  @media screen and (min-width: 0px) and (max-width: 876px) {
    margin-top: -3.5%;
  }

}

.detail_page_marker{
  font-size:medium;
  font-weight:bold;
  color: rgb(102, 3, 23);
  text-decoration: none;
  padding-left: 2%;

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: large;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    font-size: medium;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: small;
  }

}

.celebrity_page_marker{
  font-size:small;
  color:  rgb(121, 119, 119);
  align-items: center;
  margin-left: 2%;


  @media screen and (min-width: 576px) and (max-width: 992px) {
    font-size: smaller;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: smaller;
  }
}



.carousel_class{
  height:auto;
  min-height: 100px;
  max-height: 400px;

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    min-height: 100px;
    max-height: 300px;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    min-height: 100px;
    max-height: 370px;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    min-height: 100px;
    max-height: 350px;
  }


  
}




.carousel_class_what_is_book{
height:auto;
// height: 30%;
 

  // @media screen and (min-width: 992px) and (max-width: 1400px) {
  //   min-height: 100px;
  //   max-height: 200px;
  // }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    min-height: 100px;
    max-height: 240px;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    min-height: 100px;
    max-height: 170px;
  }
  
}


.detail-page-top{
  margin-top: 1%;

  height:auto;
  // width: auto;
  min-height: 100px;
  max-height: 400px;

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    margin-left: 5%;
    margin-top: 0%;
    width:20%;
    min-height: 100px;
    max-height: 400px;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    margin-left: 3%;
    width:30%;
    min-height: 100px;
    max-height: 400px;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {

    margin-left:1%;
    min-height: 100px;
    width:40%;
    max-height: 250px;
  }

}

  .detail-page-top-info{
    margin-top: 3%;
    height:auto;
    margin-left: 3%;
    // width: auto;
    width:50%;
    min-height: 100px;
    max-height: 400px;
  
    @media screen and (min-width: 992px) and (max-width: 1400px) {
      width:50%;
      min-height: 100px;
      max-height: 300px;
    }
  
    @media screen and (min-width: 576px) and (max-width: 992px) {
      width:50%;
      min-height: 100px;
      max-height: 240px;
    }
  
    @media screen and (min-width: 0px) and (max-width: 576px) {
      margin-left:1%;
      min-height: 100px;
      width:55%;
      max-height: 250px;
    }


  
}


.carousel_class_series{
  height:auto;
  min-height: 100px;
  max-height: 400px;

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    min-height: 100px;
    max-height: 300px;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    min-height: 100px;
    max-height: 240px;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    min-height: 100px;
    max-height: 240px;
  }

}

.carousel_class_list{
  height: 25px;
}

.col-md-4{
  margin-left: -28px;
}

.card{
 padding: 2%;
}

.card_show_all{
  margin-left: -10%;

  
 }

.navbar .container-fluid{
  flex-direction: row;
}

.card-group{
width: 90%;
margin-left: 12%;
@media screen and (min-width: 992px) and (max-width: 1400px) {
  width: 85%;
margin-left: 9%;

 
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  width: 99%;
  margin-left: 10%;
  
}

@media screen and (min-width: 0px) and (max-width: 576px) {
  width: 100%;
margin-left: 12%;
}
}


.show_all_header{
padding-left: 3.5%;
padding-right: 6%;
padding-top: 2%;
padding-bottom: 1.5%;
font-size:x-large;
font-weight: bolder;

  
@media screen and (min-width: 992px) and (max-width: 1400px) {
  font-size:xx-large;
 
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  font-size:xx-large
}

@media screen and (min-width: 0px) and (max-width: 576px) {
  font-size:x-large;
}

}

.carousal_detail_page{
  margin-left: 2%;
}

.detail_page_card{
  margin-left: -15%;
  padding-left: 2%;
  margin-top: 2%;
  @media screen and (min-width: 0px) and (max-width: 576px) {
    margin-left: 1%;
  }
}

.book_count{
  padding-left: 3.5%;
  margin-top: -1.7%;
  padding-bottom: 1.5%;
  font-size:small;
  font-weight: normal;
  color: rgb(95, 94, 94);
    
@media screen and (min-width: 992px) and (max-width: 1400px) {
 margin-bottom: -2%;
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  padding-bottom: 0.5%;
}

@media screen and (min-width: 0px) and (max-width: 576px) {
  
}

}

.col-sm-auto{
  margin-left: 5%;
}

.card {
  width: auto;
  margin-left: 1%;
  margin-right: -18%;
  margin-top: 4%;
  margin-bottom: 2%;
  border-radius: 4px;
  background: smokewhite;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  // padding: 14px 1px 1px 36px;
  cursor: default;
  text-align: left;


}

.carousel-dropdown{
  margin-bottom: 20%;
}


