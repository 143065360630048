.Search_App {
    text-align: center;
  }
  
  .Search_App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .Search_App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Search_App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
   margin-left: 4%;
    margin-top: 40px; /* gutter size offset */
    width: 101%;
    height: 100%;

  }
  .my-masonry-grid_column {
    padding-left: 0px; /* gutter size */
    
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: smokewhite;
    margin-bottom: 100px;
    margin-right: 40px;
  
    @media screen and (min-width: 600px) and (max-width: 900px) {
        margin-bottom: 100px;
        margin-right: 30px;
    }
  
    @media screen and (min-width: 100px) and (max-width: 600px) {
        margin-bottom: 100px;
    margin-right: 20px;
    }
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .twoColumns {
    /* display: grid; */
    grid-template-columns: 20% 75%;
    grid-gap: 5%;
  }


  .my-masonry-grid-celebrity-home {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
   margin-left: 4%;
    margin-top: 20px; /* gutter size offset */
    width: 100%;
    height: 320px;

    @media screen and (min-width: 600px) and (max-width: 900px) {
      margin-top: 15px; /* gutter size offset */
      height: 250px;
    }
  
    @media screen and (min-width: 100px) and (max-width: 600px) {
      margin-top: 10px; /* gutter size offset */
      height: 130px;
    }
  }
  .my-masonry-grid-celebrity-home_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
    margin-bottom: 3%;
    margin-left: -1%;
  }
  
  /* Style your items */
  .my-masonry-grid-celebrity-home_column > div {
    /* change div to reference your elements you put in <Masonry> */
    // background: smokewhite;
    margin-bottom: 50px;
    margin-right: 50px;
  
    @media screen and (min-width: 600px) and (max-width: 900px) {
      margin-right: 30px;
      margin-bottom: 30px;
    }
  
    @media screen and (min-width: 100px) and (max-width: 600px) {
      margin-right: 10px;
      margin-bottom: -10%;
    }
  }



  .my-masonry-grid-genre-home {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
   margin-left: 4%;
    margin-top: 20px; /* gutter size offset */
    width: 100%;
    height: 160px;
 

    @media screen and (min-width: 600px) and (max-width: 900px) {
      margin-top: 15px; /* gutter size offset */
      height: 100px;
    }
  
    @media screen and (min-width: 100px) and (max-width: 600px) {
      margin-top: 10px; /* gutter size offset */
      height: 80px;
     
    }
  }
  .my-masonry-grid-genre-home_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
    margin-bottom: 3%;
    margin-left: -1%;
  }
  
  /* Style your items */
  .my-masonry-grid-genre-home_column > div {
    /* change div to reference your elements you put in <Masonry> */
    // background: smokewhite;
    margin-bottom: 50px;
    margin-right: 50px;
  
    @media screen and (min-width: 600px) and (max-width: 900px) {
      margin-right: 30px;
      margin-bottom: 30px;
    }
  
    @media screen and (min-width: 100px) and (max-width: 600px) {
      margin-right: 10px;
      margin-bottom: -10%;
    }
  }

  .my-masonry-grid-list-home {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
   margin-left: 4%;
    margin-top: 20px; /* gutter size offset */
    margin-bottom: 20px;
    width: 100%;
    height: 360px;
 

    @media screen and (min-width: 600px) and (max-width: 900px) {
      margin-top: 15px; /* gutter size offset */
      height: 310px;
    }
  
    @media screen and (min-width: 100px) and (max-width: 600px) {
      margin-top: 10px; /* gutter size offset */
      margin-left: 1%;
      width: 110%;
      height: 260px;
     
    }
  }
  .my-masonry-grid-list-home_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
    margin-bottom: 3%;
    margin-left: -1%;
  }
  
  /* Style your items */
  .my-masonry-grid-list-home_column > div {
    /* change div to reference your elements you put in <Masonry> */
    // background: smokewhite;
    margin-bottom: 50px;
    margin-right: 50px;
  
    @media screen and (min-width: 600px) and (max-width: 900px) {
      margin-right: 30px;
      margin-bottom: 30px;
    }
  
    @media screen and (min-width: 100px) and (max-width: 600px) {
      margin-right: 10px;
      margin-bottom: -10%;
    }
  }


  
  
  
  