@mixin respond($breakpoint) {
  @if ($breakpoint==phone) {
    @media only screen and (max-width: 37.5em) {
      //600
      @content;
    }
  }
  @if ($breakpoint==tab-port) {
    @media only screen and (max-width: 56.25em) {
      //900
      @content;
    }
  }
  @if ($breakpoint==tab-land) {
    @media only screen and (max-width: 75em) {
      //1200
      @content;
    }
  }
  @if ($breakpoint==big-destop) {
    //1800
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}



.card-text {
  color: black;
  margin-top: 4px;
  margin-right: -34px;

  cursor: pointer;

  overflow-x: hidden;
  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: 2.1vw;
  }
}

.card-text-author {
  color: grey;
  margin-top: -12px;

  cursor: pointer;
  font-size: 1vw;
  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: 2.2vw;
  }
}

.card-flip-book-name {
  color: black;
  margin-top: -4px;
  font-size: 1.4vw;
  font-weight: 600;
}
.card-flip-author-name {
  color: grey;
  margin-top: -6px;
  font-size: 1vw;
  margin-bottom: -6px;
}

.card-flip-description {
  color: black;
  margin-top: 1px;
  margin-left: -4px;
  font-size: 1vw;
  text-align: left;
}

.row {
  margin-left: 20px;
  color: white;
}
h2 {
  padding-left: 20px;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
 margin-left: 4%;
  margin-top: 40px; /* gutter size offset */
  width: 90%;
  height: 100%;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
  margin-bottom: -10%;
  margin-left: -1%;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: smokewhite;
  margin-bottom: 50px;
  margin-right: 50px;

  @media screen and (min-width: 600px) and (max-width: 900px) {
    margin-right: 30px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 100px) and (max-width: 600px) {
    margin-right: 10px;
    margin-bottom: -10%;
  }
}



.card-group {
  padding: 10px;
}

.card-title {
  color: white;
  margin-top: -10px;
  cursor: pointer;
  font-size: small;
  margin-left: 5%;
  vertical-align: bottom;

  font-weight: light;


  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: 2.6vw;
  }
}


.card-title-Lists {
  font-size: large;
  align-items: left;
  margin-left: 5%;
  font-weight: bold;
  margin-top: 2%;

  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: medium;
  }
}

.card-title-genres {
  font-size: 1.6vw;
  // align-items: center;
  // margin-left: 5%;
  // margin-top: 25%;
  // margin-left: 35%;
  font-weight: bold;
  display:flex;
  justify-content:center;
  align-items: center;

  @media screen and (min-width: 0px) and (max-width: 576px) {
    // margin-top: 50%;
    // margin-left: 25%;
    font-size: 2.6vw;
  }


}

.card-title-Series {
  font-size: large;
  align-items: left;
  margin-left: 5%;
  font-weight: 400;
  margin-top: 2%;

  @media screen and (min-width: 992px) and (max-width: 1400px) {
    font-size: medium;
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    font-size: medium;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: 70%;
  }
}

.card-name-celebrity {
  font-size: large;
  align-items: center;
  font-weight: bold;
  color:black;
  margin-top: 5%;
  
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    margin-left: 5%;
    
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    font-size: medium;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    font-size: small;
    font-weight: 500;
  }
}

.card-title-celebrity {
  font-size: medium;
  align-items: left;
  margin-top: 2%;
}


.card-collage {
  width: 100%;
  height: 400px;
  border-radius: 2%;
  background: #1ACFC8;
  color: white;
  font-family: Helvetica;
  padding-bottom: 20%;
  margin-left: -10%;
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    margin-left: -3%;
  }
  @media screen and (min-width: 576px) and (max-width: 992px) {
    margin-left: -2%;
  }
  @media screen and (min-width: 0px) and (max-width: 576px) {
    height: 300px;
    margin-left: 0%;
  }
}
.card-collage-list {
  padding-right: 0%;
}
.card-collage-celebrity {
  width: 100%;
  // height: 400px;
  border-radius: 2%;
  background: transparent;
  color: grey;
  font-family: Helvetica;
  padding-bottom: 4%;
  padding-top: -4%;
  margin-left: 4%;
  @media screen and (min-width: 992px) and (max-width: 1400px) {
  }
  @media screen and (min-width: 576px) and (max-width: 992px) {
  }
  @media screen and (min-width: 0px) and (max-width: 576px) {
  }
}



.card-collage-series {
  width: 90%;
  height: 220px;
  border-radius: 2%;
  margin-top: 2%;

  background: whitesmoke;
  color: rgb(48, 47, 47);
  font-family: Helvetica;
  padding-bottom: 20%;
  margin-left: 10%;  
  @media screen and (min-width: 992px) and (max-width: 1400px) {
   
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    margin-left: 6%;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    height: 120px;
    margin-left: 2%;
  }
}

.card-collage_2 {
  width: 100%;
  height: 400px;
  border-radius: 2%;
  background: #4A4A4A;
  color: white;
  font-family: Helvetica;
  padding-bottom: 20%;
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    margin-left: -3%;
  }
  @media screen and (min-width: 576px) and (max-width: 992px) {
    margin-left: -2%;
  }
  @media screen and (min-width: 0px) and (max-width: 576px) {
    height: 300px;
    margin-left: 0%;
  }
}

.card-collage_3 {
  width: 100%;
  height: 400px;
  border-radius: 2%;
  background: #15A7CD;
  color: white;
  font-family: Helvetica;
  padding-bottom: 20%;
  margin-left: 8%;
  
  @media screen and (min-width: 992px) and (max-width: 1400px) {
    margin-left: -3%;
  }
  @media screen and (min-width: 576px) and (max-width: 992px) {
    margin-left: -2%;
  }
  @media screen and (min-width: 0px) and (max-width: 576px) {
    height: 300px;
    margin-left: 0%;
  }
}


.card-collage-genre {
  width: 100%;
  height: 150px;
  border-radius: 5%;
  background: rgb(61, 58, 58);
  color: white;
  font-family: Helvetica;
  align-content: center;
  display:flex;
  justify-content:center;
  align-items: center;

  @media screen and (min-width: 600px) and (max-width: 900px) {
    height: 120px;
    
  }

  @media screen and (min-width: 100px) and (max-width: 600px) {
    height: 70px;
  }
}
.grid-container-collage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  padding-bottom: 1%; /* The grid-template-columns property specifies the number (and the widths) of columns in a grid layout. */
  :hover{
    background-color: #000;
}
}

img {
  width: 100%;
  height: 100%;
  padding: 0%;
 
}



.card-body {
  margin-left: -25%;

  @media screen and (min-width: 600px) and (max-width: 900px) {
    margin-left: -20%;
  }

  @media screen and (min-width: 100px) and (max-width: 600px) {
    margin-left: -10%;
  }
}

.img-fluid {
  margin-left: -11%;
  height: 200px;

  @media screen and (min-width: 600px) and (max-width: 900px) {
    margin-left: -9%;
    height: 100px;
  }

  @media screen and (min-width: 100px) and (max-width: 600px) {
    margin-left: -10%;
    height: 100px;
  }
}

.FlippyCard {
  padding: 5%;

}




.FlippyCardSearch {
  padding: -5%;
}

.carousel-item-image {
  width: 98%;
  border-radius: 1%;
  object-fit: fill;
  cursor: pointer;

  transition: all 0.3s;

  &:hover {
    transform: scale(1.02);
  }

  @media screen and (min-width: 600px) and (max-width: 900px) {
    width: 98%;

    border-radius: 5%;
  }

  @media screen and (min-width: 100px) and (max-width: 600px) {
    width: 90%;
    -webkit-perspective: 2000;
    -webkit-backface-visibility: hidden;
  }
}

.carousel-image-lists {
  // width: 100%;
  height: 100px;

  align-items:flex-end;

  border-radius: 1%;
  object-fit: fill;
  // cursor: auto;

  transition: all 0.3s;
  @media screen and (min-width: 600px) and (max-width: 900px) {
    height: 100px;
  }

  @media screen and (min-width: 100px) and (max-width: 600px) {
    height: 80px;
    -webkit-perspective: 2000;
    -webkit-backface-visibility: hidden;
  }
}



.card-collage-list-home {
  width: 100%;
  height: 400px;
  border-radius: 2%;
  background: #1ACFC8;
  color: white;
  font-family: Helvetica;
  padding-bottom: 20%;
  margin-left: -10%;
  @media screen and (min-width: 992px) and (max-width: 1400px) {
  }
  @media screen and (min-width: 576px) and (max-width: 992px) {
    height: 300px;
  }
  @media screen and (min-width: 0px) and (max-width: 576px) {
    height: 250px;
  }
}

.card-collage-list2-home {
  width: 100%;
  height: 400px;
  border-radius: 2%;
  background: #4A4A4A;
  color: white;
  font-family: Helvetica;
  padding-bottom: 20%;
  margin-left: -10%;
  @media screen and (min-width: 992px) and (max-width: 1400px) {
  }
  @media screen and (min-width: 576px) and (max-width: 992px) {
    height: 300px;
  }
  @media screen and (min-width: 0px) and (max-width: 576px) {
    height: 250px;
  }
}





.card-collage-list3-home {
  width: 100%;
  height: 400px;
  border-radius: 2%;
  background: #15A7CD;
  color: white;
  font-family: Helvetica;
  padding-bottom: 20%;
  margin-left: -10%;
  @media screen and (min-width: 992px) and (max-width: 1400px) {
  }
  @media screen and (min-width: 576px) and (max-width: 992px) {
    height: 300px;
  }
  @media screen and (min-width: 0px) and (max-width: 576px) {
    height: 250px;
  }
}














