.Navbar {
  width: 100%;
  height: 80px;
  width:100%;
  display: flex;
}



.Navbar .LeftSide {
  flex: 70%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 50px;
}

.Navbar .LeftSide .links {
  max-height: 80px;
  margin-left: 20px;
  /* margin-left: auto; */
}



.Navbar .LeftSide .links a {
  text-decoration: none;
  color:black;
  font-size: 15px;
  margin-left: 10px;
}


.Navbar .LeftSide button {
  display:none;
  margin-left: 20px;
}



.Navbar .RightSide {
  flex: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 25px;
}



@media only screen and (max-width: 850px) {
  .Navbar .LeftSide .links a {
    font-size:15px;

  }
}

@media only screen and (max-width: 750px) {
  .Navbar .LeftSide .links a {
    font-size:12px;

  }
}

@media only screen and (max-width: 650px) {
  .Navbar .LeftSide button {
    display:flex;
  } 

  .Navbar .LeftSide .links a1 {
    display:none
     }

     .Navbar .LeftSide .links {
      display:none
       }


}


.header > img{
    object-fit: contain;
    height: 80px    ;
}

.header__icons{
    display: flex;
    align-items: auto;
    text-align: center;
    /* margin:0 auto; */
}

.header__icon > .MuiSvgIcon-root{
  /* margin: auto;   */
  margin-left: auto;
  margin-right: auto;
}

.header__icon--active > p{
    display: inline-flex !important;
}
.header__icon:hover > p {
    display: inline-flex;
}

.header__icon > p{
    display: flex;
    text-transform: capitalize;
    letter-spacing: 3px;
    margin-left: 10%;
    /* margin-right: auto; */ 
    font-size: medium;
    /* margin: 0; */
    letter-spacing: 2px;

}

.header__icon{
    display: flex;
    flex-direction: column; 
    width: 100px;
    cursor: pointer;
    text-align: end;
    padding: 0.5px;
    top: 50%;
    left: 50%;
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.5s;
  }
  
  
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 0px;
    right: 45px;
    font-size: 80px;
    color:black
  }
  
  @media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    color:black
  }
  }
  
  .overlay h2, .overlay p {
  color:black;
  }


.nav-brand-1 {
  font-size:small;
  margin-bottom: -15%;
}
 